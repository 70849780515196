import '@fortawesome/fontawesome-free/css/all.css'
import keyBy from 'lodash/keyBy'
import { useMemo, useState } from 'react'
import axios from 'axios'
import download from 'js-file-download'

import * as config from './config'
import './App.css';
import { DefinitionsContext } from './providers'
import { loadKeycodes } from './keycodes'
import { loadBehaviours } from './api'
import KeyboardPicker from './Pickers/KeyboardPicker';
import Spinner from './Common/Spinner';
import Keyboard from './Keyboard/Keyboard'
import GitHubLink from './GitHubLink'
import Loader from './Common/Loader'
import github from './Pickers/Github/api'

function App() {
  const [definitions, setDefinitions] = useState(null)
  const [source, setSource] = useState(null)
  const [sourceOther, setSourceOther] = useState(null)
  const [layout, setLayout] = useState(null)
  const [keymap, setKeymap] = useState(null)
  const [editingKeymap, setEditingKeymap] = useState(null)
  const [buildingKeymap, setBuildingKeymap] = useState(null)
  const [saving, setSaving] = useState(false)

  async function handleCompile() {
    setBuildingKeymap(true);
    alert('Keymap sent to build server. A firmware download will begin shortly, please leave this page open.')
    const selectedLayout = localStorage.getItem('selectedLayout')
    const response = await fetch(`${config.apiBaseUrl}/keymap/${selectedLayout}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editingKeymap || keymap)
    })
    const compile = await response.json()
    if (compile.file == 'duplicate') {
	    return;
    } else {
            handleDownload(compile.file)
    }
  }

  function handleDownload(fileName) {
    axios.get(`${config.apiBaseUrl}/download/${fileName}`, {
	    responseType: 'blob',
      }).then(res => {
	      setBuildingKeymap(false);
	      download(res.data, `${fileName}-zmk.uf2`);
      })
    .catch((error) => {
      alert(error);
    })
  }

  async function handleDefaultDownload() {
    const selectedLayout = localStorage.getItem('selectedLayout')
    axios.get(`${config.apiBaseUrl}/defaultdownload/${selectedLayout}`, {
	    responseType: 'blob',
      }).then(res => {
	      download(res.data, `${selectedLayout}-zmk.uf2`);
      })
    .catch((error) => {
      alert(error);
    })
  }

  const handleCommitChanges = useMemo(() => function() {
    const { repository, branch } = sourceOther.github

    ;(async function () {
      setSaving(true)
      await github.commitChanges(repository, branch, layout, editingKeymap)
      setSaving(false)

      setKeymap(editingKeymap)
      setEditingKeymap(null)
    })()
  }, [
    layout,
    editingKeymap,
    sourceOther,
    setSaving,
    setKeymap,
    setEditingKeymap
  ])

  const handleKeyboardSelected = useMemo(() => function(event) {
    const { source, layout, keymap, ...other } = event

    setSource(source)
    setSourceOther(other)
    setLayout(layout)
    setKeymap(keymap)
    setEditingKeymap(null)
  }, [
    setSource,
    setSourceOther,
    setLayout,
    setKeymap,
    setEditingKeymap
  ])

  const initialize = useMemo(() => {
    return async function () {
      const [keycodes, behaviours] = await Promise.all([
        loadKeycodes(),
        loadBehaviours()
      ])

      keycodes.indexed = keyBy(keycodes, 'code')
      behaviours.indexed = keyBy(behaviours, 'code')

      setDefinitions({ keycodes, behaviours })
    }
  }, [setDefinitions])

  const handleUpdateKeymap = useMemo(() => function(keymap) {
    setEditingKeymap(keymap)
  }, [setEditingKeymap])

  return (
    <>
      <Loader load={initialize}>
        <div id="keyboard-selector">    
          <KeyboardPicker onSelect={handleKeyboardSelected} />
        </div>
        <div id="actions">
	  {buildingKeymap === true && (
	    <p> Building Firmware, please wait.. </p>
	  )}
          {source === 'local' && (
            <button disabled={!editingKeymap || buildingKeymap} onClick={handleCompile}>
              Build Firmware
            </button>
          )}
          {source === 'local' && (
            <button disabled={editingKeymap} onClick={handleDefaultDownload}>
              Default Firmware
            </button>
          )}
          {source === 'github' && (
            <button
              title="Commit keymap changes to GitHub repository"
              disabled={!editingKeymap}
              onClick={handleCommitChanges}
            >
              {saving ? 'Saving' : 'Commit Changes'}
              {saving && <Spinner />}
            </button>
          )}
        </div>
        <DefinitionsContext.Provider value={definitions}>
          {layout && keymap && (
            <Keyboard
              layout={layout}
              keymap={editingKeymap || keymap}
              onUpdate={handleUpdateKeymap}
            />
          )}
        </DefinitionsContext.Provider>
      </Loader>
      <GitHubLink className="github-link" />
    </>
  );
}

export default App;
